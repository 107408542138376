import { NgxPermissionsService } from 'ngx-permissions';

/* eslint-disable @typescript-eslint/ban-types */
import { HttpErrorResponse } from '@angular/common/http';
import { computed, inject, Signal, Type } from '@angular/core';
import {
  PERMISSION_CREATE,
  PERMISSION_DELETE,
  PERMISSION_READ,
  PERMISSION_UPDATE,
  ROUTER_FACADE_TOKEN,
} from '@do/app-common';
import { LoaderStore } from '@do/app-loader';
import { BaseDto } from '@do/common-dto';
import { FilterValue, PagedResultDto } from '@do/common-interfaces';
import { DeepPartial } from '@do/common-utils';
import {
  patchState,
  signalStoreFeature,
  withComputed,
  withMethods,
  withState,
} from '@ngrx/signals';
import {
  addEntity,
  EntityId,
  EntityMap,
  removeEntity,
  setAllEntities,
  setEntities,
  setEntity,
  updateEntity,
  withEntities,
} from '@ngrx/signals/entities';
import { DeepSignal } from '@ngrx/signals/src/deep-signal';

import { BaseCRUDApiClient } from './baseCRUD.api-client';
import { toSignal } from '@angular/core/rxjs-interop';

export interface EntityDataState<E extends BaseDto> {
  loadedAll: boolean;
  error: string | undefined;
  entityMap: EntityMap<E>;
  entities: E[];
  ids: EntityId[];
}

export interface EntityDataComputedState {
  canDelete: boolean;
  canRead: boolean;
  canUpdate: boolean;
  canCreate: boolean;
}

export type SignalState<S> = {
  [K in keyof S]: S[K] extends object ? DeepSignal<S[K]> : Signal<S[K]>;
};

export type SignalProperties<S> = {
  [K in keyof S]: Signal<S[K]>;
};

export type EntityDataMethods<E extends BaseDto> = {
  loadAll(): Promise<void>;
  loadPaged(
    take: number,
    skip: number,
    orderBy?:
      | {
          [field: string]: 'asc' | 'desc';
        }
      | undefined,
    filters?: FilterValue[],
    join?: string[]
  ): Promise<PagedResultDto<E> | null>;
  loadSingle(id: string): Promise<E | undefined>;
  update(id: string, data: DeepPartial<E>): Promise<E | undefined>;
  add(data: DeepPartial<E>): Promise<E | undefined>;
  added(data: E): void;
  delete(id: string): Promise<boolean>;
  deleted(id: string): void;
  updated(id: string, data: E): void;
};

export type EntityDataSignalStore<E extends BaseDto> = EntityDataMethods<E> &
  SignalState<EntityDataState<E>> &
  SignalState<EntityDataComputedState>;

export function withEntityDomainStore<
  E extends BaseDto,
  S extends EntityDataState<E>,
  A extends BaseCRUDApiClient<E>
>(
  apiClientType: Type<A>,
  initialState: Omit<S, keyof EntityDataState<E>>,
  entityName: string
) {
  const defaultInitialState: EntityDataState<E> = {
    loadedAll: false,
    error: '',
    entityMap: {},
    entities: [],
    ids: [],
  };

  return signalStoreFeature(
    withState<EntityDataState<E>>(
      Object.assign(defaultInitialState, initialState || {})
    ),
    withEntities<E>(),
    withMethods((state) => {
      const apiClient = inject(apiClientType);
      const loaderStore = inject(LoaderStore);

      const deleted = (id: EntityId) => {
        patchState(state, removeEntity(id));
      };
      const updated = (id: EntityId, data: E) => {
        patchState(state, updateEntity({ id, changes: data }));
      };
      const added = (data: E) => {
        patchState(state, addEntity(data));
      };

      const methods: EntityDataMethods<E> = {
        async loadAll() {
          const actionId = loaderStore.showLoader();

          try {
            const result = await apiClient.getAllPromise();
            patchState(state, setAllEntities(result));
            patchState(state, { loadedAll: true });
          } catch (error) {
            if (error instanceof HttpErrorResponse) {
              patchState(state, { error: error.message });
            }
          } finally {
            loaderStore.hideLoader(actionId);
          }
        },
        async loadPaged(
          take: number,
          skip: number,
          orderBy?: { [field: string]: 'asc' | 'desc' },
          filters?: FilterValue[],
          join?: string[]
        ) {
          const actionId = loaderStore.showLoader();

          try {
            const result = await apiClient.getPagedPromise(
              take,
              skip,
              orderBy,
              filters,
              join
            );
            patchState(state, setEntities(result.items));
            return result;
          } catch (error) {
            if (error instanceof HttpErrorResponse) {
              patchState(state, { error: error.message });
            }
            return null;
          } finally {
            loaderStore.hideLoader(actionId);
          }
        },
        async loadSingle(id: EntityId) {
          const actionId = loaderStore.showLoader();
          let result: E | undefined;
          try {
            result = await apiClient.getByIdPromise(id.toString());
            patchState(state, setEntity(result));
          } catch (error) {
            if (error instanceof HttpErrorResponse) {
              patchState(state, { error: error.message });
            }
          } finally {
            loaderStore.hideLoader(actionId);
          }

          return result;
        },
        async update(id: EntityId, data: DeepPartial<E>) {
          const actionId = loaderStore.showLoader();
          let result: E | undefined;

          try {
            result = await apiClient.updatePromise(id.toString(), data);
            updated(id, result);
          } catch (error) {
            if (error instanceof HttpErrorResponse) {
              patchState(state, { error: error.message });
            }
          } finally {
            loaderStore.hideLoader(actionId);
          }
          return result;
        },
        async add(data: DeepPartial<E>) {
          const actionId = loaderStore.showLoader();
          let result: E | undefined;

          try {
            result = await apiClient.addPromise(data);
            added(result);
          } catch (error) {
            if (error instanceof HttpErrorResponse) {
              patchState(state, { error: error.message });
            }
          } finally {
            loaderStore.hideLoader(actionId);
          }
          return result;
        },
        async delete(id: EntityId) {
          const actionId = loaderStore.showLoader();
          let result = false;
          try {
            await apiClient.deletePromise(id.toString());
            deleted(id);
            result = true;
          } catch (error) {
            if (error instanceof HttpErrorResponse) {
              patchState(state, { error: error.message });
            }
          } finally {
            loaderStore.hideLoader(actionId);
          }

          return result;
        },
        updated,
        deleted,
        added,
      };
      return methods;
    }),
    withComputed(() => {
      const permissionsService = inject(NgxPermissionsService);
      const routerFacade = inject(ROUTER_FACADE_TOKEN);
      const permissionSignal = toSignal(permissionsService.permissions$);
      const computedState: SignalState<EntityDataComputedState> = {
        canDelete: computed(() => {
          const p = permissionSignal();
          if (!p) return false;

          const isViewOnlyMode = routerFacade.isViewOnlyMode();
          const isFindMode = routerFacade.isFindMode();
          if (isViewOnlyMode || isFindMode) return false;

          return p[PERMISSION_DELETE + '_' + entityName] != null;
        }),
        canUpdate: computed(() => {
          const p = permissionSignal();
          if (!p) return false;

          const isViewOnlyMode = routerFacade.isViewOnlyMode();
          const isFindMode = routerFacade.isFindMode();
          if (isViewOnlyMode || isFindMode) return false;

          return p[PERMISSION_UPDATE + '_' + entityName] != null;
        }),
        canCreate: computed(() => {
          const p = permissionSignal();
          if (!p) return false;

          const isViewOnlyMode = routerFacade.isViewOnlyMode();
          const isFindMode = routerFacade.isFindMode();
          if (isViewOnlyMode || isFindMode) return false;

          return p[PERMISSION_CREATE + '_' + entityName] != null;
        }),
        canRead: computed(() => {
          const p = permissionSignal();
          if (!p) return false;

          const isFindMode = routerFacade.isFindMode();
          if (isFindMode) return false;

          return p[PERMISSION_READ + '_' + entityName] != null;
        }),
      };

      return computedState;
    })
  );
}
